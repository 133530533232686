import React, { useEffect, createRef, useCallback } from "react";
import {facebookLink, instagramLink} from "../config";

// reactstrap components
import { 
  Container,
  Row,
  Col 
} from "reactstrap";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';


import { eaganMapLink, wblMapLink, stpMapLink } from '../config';

// css
import "./NaturallyAlignedFooter.css";

function NaturallyAlignedFooter(props) {
  let locationOneRef = createRef();
  let locationTwoRef = createRef();
  let locationThreeRef = createRef();
  let locationRowRef = createRef();

  const modifyLocationsIfNeccessary = useCallback(() => {
    if (window.innerWidth < 400) {
      // add outer col elements on each ref
      locationThreeRef.current.classList.add('col');
      locationTwoRef.current.classList.add('col');
      locationOneRef.current.classList.add('col');
      locationRowRef.current.classList.add('row');
    } else {
      locationThreeRef.current.classList.remove('col');
      locationTwoRef.current.classList.remove('col');
      locationOneRef.current.classList.remove('col');
      locationRowRef.current.classList.remove('row');
    }
  }, [locationOneRef, locationTwoRef, locationThreeRef, locationRowRef]);

  useEffect(() => {
    modifyLocationsIfNeccessary();

    const handleResize = () => {
      modifyLocationsIfNeccessary();
    }

    window.addEventListener('resize', handleResize);
    
    return function cleanup()  {
      window.removeEventListener('resize', handleResize);
    };
  }, [ modifyLocationsIfNeccessary]);

  return (
    <>
      <footer className="na-footer footer footer-default">
        <Container>
          <Row className="na-footer-base-row">
            <Col md>
              <div className="na-footer-title">
                Locations
              </div>
              <div className="na-location-small-row" ref={locationRowRef}>
                <div className="na-location-one" ref={locationOneRef}>
                  <a href={stpMapLink} target="_blank" rel="noopener noreferrer">
                    <div className="na-footer-emphasis">
                      St. Paul
                    </div>
                  </a>
                  <div className="na-footer-small">
                    (within <a href="https://www.health-foundations.com/" target="_blank" rel="noopener noreferrer">Health Foundations</a>)
                  </div>
                  <a href={stpMapLink} target="_blank" rel="noopener noreferrer">
                    968 Grand Ave<br/>
                    St. Paul, MN 55105
                  </a>
                </div>
                <div className="na-location-spacer"></div>
                <div className="na-location-two" ref={locationTwoRef}>
                  <a href={wblMapLink} target="_blank" rel="noopener noreferrer">
                    <div className="na-footer-emphasis">
                      White Bear Lake
                    </div>
                  </a>
                  <div className="na-footer-small">
                    (within <a href="https://www.naturallywellwbl.com/" target="_blank" rel="noopener noreferrer">Naturally Well</a>)
                  </div>
                  <a href={wblMapLink} target="_blank" rel="noopener noreferrer">
                    2025 4<sup>th</sup> Street<br/>
                    White Bear Lake, MN 55110
                  </a>
                </div>
                <div className="na-location-spacer"></div>
                <div className="na-location-three" ref={locationThreeRef}>
                  <a href={eaganMapLink} target="_blank" rel="noopener noreferrer">
                    <div className="na-footer-emphasis">
                      Eagan
                    </div>
                  </a>
                  <div className="na-footer-small">
                    (within <a href="https://wildtreewellness.com/" target="_blank" rel="noopener noreferrer">Wild Tree Wellness</a>)
                  </div>
                  <a href={eaganMapLink} target="_blank" rel="noopener noreferrer">
                    1519 Central Parkway, Ste 260
                    Eagan, MN 55121
                  </a>
                </div>
              </div>
            </Col>
            <Col xs className="na-footer-connect-col">
                <div className="na-footer-title">
                  <div id="contact">Connect with us</div>
                </div>
              Phone: <a href="tel:651-210-5261">651.210.5261</a><br/>
              Send us an <a href="mailto:info@naturallyaligned.com?subject=Connect from website">email</a><br/>
              <a href="https://eepurl.com/cPN1gz" target="_blank" rel="noopener noreferrer">Join our Mailing List</a><br />
              <p/>
              <div className="na-footer-social-media-links">
                <a href={facebookLink} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookSquare} size="2x"/>
                </a>
                &nbsp;
                <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              </div>
            </Col>
            <Col xs className="na-footer-links-col">
              <div className="na-footer-title">
                Links
              </div>
              <Link to="/about-us">Meet Our Team</Link><br />
              <Link to="/payment-options">Payment Options</Link><br />
              {/* Resources for Clients<br /> */}
              <Link to="/faq">FAQs</Link><br />
              <a href="mailto:info@naturallyaligned.com?subject=Testimonial">Submit a Testimonial</a><br />
              <a href="mailto:info@naturallyaligned.com?subject=Feedback">Provide Feedback</a><br />
            </Col>
          </Row>
          <Row>
              <Col>
              <div className="na-copyright" id="copyright">
                © {new Date().getFullYear()} Naturally Aligned Chiropractic and Wellness
              </div>
              </Col>
           </Row>
        </Container>
      </footer>
    </>
  );
}

export default NaturallyAlignedFooter;
